import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Newsletter from '../components/Newsletter'
import styled from 'styled-components'
import { eggheadRefId } from '../config'

const StyledLink = styled(Link)`
  font-size: 18px;

  color: ${props => props.theme.linkColor};

  @media (max-width: 650px) {
    font-size: 15px;
  }
`

const Intro = styled.section`
  background-color: #3e86e4;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  a {
    // text-shadow: none;
    // color: #1f2933;
    padding: 2px;
    background-color: #ee5253;
    color: #fff;
    border-radius: 4px;
  }

  margin-bottom: 40px;

  p {
    font-size: 1.1rem;
  }

  p.medium {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width: 375px) {
      font-size: 1.2rem;
    }
  }

  p.large {
    margin: 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;

    @media (max-width: 760px) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
`

const LatestContent = styled.section`
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  .items {
    padding-left: 20px;

    a {
      display: block;

      &:hover {
        color: #ee5253;
      }
    }

    h3 {
      font-size: 1.1rem;
    }
  }
`

export default function BlogIndex({ data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const videos = data.allEgghead.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Latest Articles"
        keywords={[`blog`, `python`, `javascript`, `react`]}
      />
      <Intro>
        <p className="medium">Hey, I'm</p>
        <p className="large">Steven Mercatante</p>
        <p className="medium">and I build things for the web.</p>
        <p>
          I'm a full stack software engineer with over 15 years of experience
          building websites, mobile apps, APIs, CMSs, and ETL pipelines. I've
          worked with Google, PBS, Hearst, NBC, and{' '}
          <Link to="/work">plenty more awesome folks</Link>.
        </p>
        <p>
          <a href="mailto:steve@stevemerc.com">Contact me</a> if you need help
          with a project.
        </p>
      </Intro>

      <LatestContent>
        <div className="header">
          <h2>Latest Articles</h2>
          <StyledLink to="/articles">View all</StyledLink>
        </div>
        <div className="items">
          {posts.map((post, i) => (
            <Link to={post.node.fields.slug} key={i}>
              <h3>{post.node.frontmatter.title}</h3>
            </Link>
          ))}
        </div>
      </LatestContent>

      <LatestContent>
        <div className="header">
          <h2>Latest Videos</h2>
          <StyledLink to="/videos">View all</StyledLink>
        </div>
        <div className="items">
          {videos.map((video, i) => (
            <a href={`${video.node.url}?af=${eggheadRefId}`} key={i}>
              <h3>{video.node.title}</h3>
            </a>
          ))}
        </div>
      </LatestContent>

      <Newsletter referrer="home" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query LatestArticles($currentDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: {
        fields: { collection: { eq: "blog" } }
        frontmatter: { published: { eq: true }, date: { lte: $currentDate } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            slug
          }
        }
      }
    }
    allEgghead(sort: { fields: publishedAt, order: DESC }, limit: 5) {
      edges {
        node {
          title
          url
          tags
        }
      }
    }
  }
`
